import { ref, watchEffect } from 'vue'
import { db } from '@/firebase/config'
import { doc, onSnapshot } from 'firebase/firestore'

const getDocument = (c, id) => {
  const error = ref(null)
  const document = ref(null)
  // console.log('col', c, 'id', id)


  const unsub = onSnapshot(doc(db, c, id), (doc) => {
    if (doc.data()) {
      // console.log('doc snapshot')
      document.value = { ...doc.data(), id: doc.id }
      error.value = null
    } else {
      error.value = 'That doc doesnt exist'
    }
  }, (err) => {
    console.log(err.message)
    document.value = null
    error.value = 'could not fetch document'
  })
  return { document, error }
}

watchEffect((onInvalidate) => {
  onInvalidate(() => unsub())
})

export default getDocument
<template>
  <div class="home">
    <div class="error" v-if="error">
      {{ error }}
    </div>
    <div class="collection" v-if="playlist">
        <ListView :playlists="playlist" />
    </div>
  </div>
</template>

<script setup>
import getCollection from '@/composables/getCollection'
import ListView from '@/components/ListView.vue'

//get Collection
const { documents: playlist, error } = getCollection('playlists')

</script>
import { signOut } from 'firebase/auth'
import { auth } from '@/firebase/config'
import { ref } from 'vue'

const error = ref(null)
const isPending = ref(false)

const logout = async () => {
  error.value = null
  isPending.value = true
  // await new Promise(resolve => setTimeout(resolve, 4000))
  try {
    await signOut(auth)
    isPending.value = false
  } catch (err) {
    error.value = err.message
    console.log(error.value)
    isPending.value = false
  }
}

const useLogout = () => {
  return { error, logout, isPending }
}

export default useLogout
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signOut, RecaptchaVerifier } from 'firebase/auth'
import { auth } from '@/firebase/config'
import { ref } from 'vue'

const error = ref(null)
const isPending = ref(false)


const login = async (email, password) => {
  error.value = null
  isPending.value = true
  // await new Promise(resolve => setTimeout(resolve, 4000))
  try {
    const res = await signInWithEmailAndPassword(auth, email, password)
    if (!res.user.emailVerified) {
      await signOut(auth)
      isPending.value = false
      throw new Error('Please verify your email before logging in.')
    }
    error.value = null
    isPending.value = false
  } catch (err) {
    if (err.message === 'Please verify your email before logging in.') {
      error.value = err.message
    } else {
      error.value = 'Incorrect Login credentials'
      isPending.value = false
    }
  }
}

const signInWithGoogle = async () => {
  error.value = null
  isPending.value = true
  try {
    const provider = new GoogleAuthProvider()
    const res = await signInWithPopup(auth, provider)
    isPending.value = false
    if (!res) {
      throw new Error('Could not complete the signup with Google')
    }
  } catch (err) {
    error.value = err.message
    isPending.value = false
    console.log(error.value)
  }
}

const useLogin = () => {
  return { error, login, isPending, signInWithGoogle }
}

export default useLogin


<template>
  <div class="navbar">
    <nav>
      <RouterLink :to="{ name: 'home' }"><img src="@/assets/logo.png"></RouterLink>
      <h1>
        <RouterLink :to="{ name: 'home' }">Playlists Project</RouterLink>
      </h1>
      <div class="links">
        <div v-if="user">
          <RouterLink :to="{ name: 'Myplaylists' }">My Playlists</RouterLink>
          <RouterLink :to="{ name: 'CreatePlaylists' }">Create Playlist</RouterLink>
          <span>Hey! {{ user.displayName }}</span>
          <button v-if="!isPending" @click="handleLogout">Logout</button>
          <button disabled v-if="isPending">Logging out...</button>
        </div>
        <div v-else>
          <RouterLink class="btn" :to="{ name: 'signup' }">SignUp</RouterLink>
          <RouterLink class="btn" :to="{ name: 'login' }">Login</RouterLink>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup>
// Imports
import useLogout from '@/composables/useLogout'
import getUser from '@/composables/getUser'
import { RouterLink, useRouter } from 'vue-router'

// router
const router = useRouter()

// getUser
const { user } = getUser()

// logout
const { error, logout, isPending } = useLogout()

// handleLogout
const handleLogout = async () => {
  await logout()
  if (!error.value) {
    router.push({ name: 'login' })
  }
}
</script>

<style scoped>
.navbar {
  padding: 16px 10px;
  margin-bottom: 60px;
  background: white;
}

nav {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

nav h1 {
  margin-left: 20px;
}

nav .links {
  margin-left: auto;
}

nav .links a,
button {
  margin-left: 16px;
  font-size: 14px;
}


nav img {
  max-height: 60px;
}

span {
  font-size: 14px;
  display: inline-block;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid #eee;
}
</style>
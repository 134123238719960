<template>
  <form @submit.prevent="handleSubmit">
    <input type="email" placeholder="Email" v-model="email" autocomplete="email">
    <input type="password" placeholder="Password" v-model="password" autocomplete="current-password">
    <button v-if="!isPending" ref="signinButton">Sign in</button>
    <button disabled v-if="isPending">Loading...</button>
    <button class="btn-right" @click="handleGoogleSignin" v-if="!isPending">Sign in with Google</button>
    <p>Dont have an account? <RouterLink class="link" to="/signup">Register here</RouterLink>
    </p>
    <div v-if="error" class="error">{{ error }}</div>
  </form>
</template>

<script setup>
import { ref } from 'vue'
import useLogin from '@/composables/useLogin'
import { useRouter } from 'vue-router'

const router = useRouter()

const email = ref('')
const password = ref('')
const signinButton = ref('')

const { error, login, phoneLogin, isPending, signInWithGoogle } = useLogin()

const handleSubmit = async () => {
  if (email.value) {
    const res = await login(email.value, password.value)
    if (!error.value) {
      router.push({ name: 'Myplaylists' })
    }
    else {
      email.value = ''
      password.value = ''
    }
  }
}

const handleGoogleSignin = async () => {
  const res = await signInWithGoogle()
  if (!error.value) {
    console.log('User is signed in!')
    router.push({ name: 'Myplaylists' })
  }
}

</script>
<style>
p {
  padding-top: 20px;
}

.link {
  color: lightblue;
}

.btn-right {
  margin-left: 5px;
}
</style>
<template>
  <div>
    <h3 v-if="pendingVerification">Please verify your email to continue</h3>
  </div>
  <div v-if="!pendingVerification">
    <form @submit.prevent="handleSubmit">
      <input type="text" placeholder="Display Name" v-model="displayName">
      <input type="email" placeholder="Email" v-model="email" autocomplete="username">
      <input type="password" placeholder="Password" v-model="password" autocomplete="current-password">
      <div v-if="error" class="error">{{ error }}</div>
      <button v-if="!isPending">Register</button>
      <p>Already have an account? <RouterLink class="link" to="/login">Login here</RouterLink>
      </p>
      <button disabled v-if="isPending">Loading...</button>
    </form>

  </div>
</template>

<script setup>
import { ref } from 'vue'
import useSignUp from '@/composables/useSignUp'
import useLogout from '@/composables/useLogout'
import { useRouter } from 'vue-router'

const router = useRouter()

const displayName = ref('')
const email = ref('')
const password = ref('')
const pendingVerification = ref(false)

const { error, signup, isPending } = useSignUp()
const { logout } = useLogout()

const handleSubmit = async () => {
  const res = await signup(email.value, password.value, displayName.value)
  pendingVerification.value == true
  logout()
  if (!error.value) {
    console.log('User is registered, Please verify your email')
    alert('Please check your email and verify')
    router.push({ name: 'login' })
  }
  else {
    displayName.value = ''
    email.value = ''
    password.value = ''
  }
}

</script>

<style>
p {
  padding-top: 20px;
}

.link {
  color: lightblue;
}

h3 {
  text-align: center;
}
</style>
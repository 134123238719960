import { db } from '@/firebase/config'
import { ref } from 'vue'
import { doc, deleteDoc, updateDoc } from 'firebase/firestore'

const useDocument = (collection, id) => {
  const errorRef = ref(null)
  const isPending = ref(false)

  // Reference to the document
  const docRef = doc(db, collection, id)

  const deleteDocument = async () => {
    isPending.value = true
    errorRef.value = null
    try {
      await deleteDoc(docRef)
      isPending.value = false
    } catch (error) {
      errorRef.value = 'Could not delete the document'
      console.log(error.message)
      isPending.value = false
    }
  }

  const updateDocument = async (updates) => {
    isPending.value = true
    errorRef.value = null
    try {
      await updateDoc(docRef, updates)
      isPending.value = false
    } catch (error) {
      errorRef.value = 'Could not update the document'
      console.log(error.message)
      isPending.value = false
    }
  }

  return { errorRef, deleteDocument, updateDocument, isPending }
}

export default useDocument
<template>
  <div class="error">{{ error }}</div>
  <div v-if="playlist" class="playlist-details">
    <div class="playlist-info">
      <div class="cover">
        <img :src="playlist.coverUrl">
      </div>
      <h2>{{ playlist.title }}</h2>
      <p class="username">Created by {{ playlist.username }}</p>
      <p class="description">{{ playlist.description }}</p>
      <div class="delete" v-if="ownership && !isPending">
        <button @click="handleDeletePlaylist" class="btn-delete">Delete Playlist</button>
      </div>
      <div class="delete" v-if="ownership && isPending">
        <button disabled class="btn-delete">Deleting...</button>
      </div>
      <div class="error" v-if="errorRef">
        {{ errorRef }}
      </div>
    </div>
    <div class="song-list">
      <div v-if="!playlist.songs.length">No songs have been added to this playlist yet.</div>
      <button v-if="ownership" class="btn" @click.prevent="showAddSong = true">Add Song</button>
      <div class="single-song" v-for="song in playlist.songs" :key="song.id">
        <div class="details">
          <h3>{{ song.title }}</h3>
          <p>{{ song.artist }}</p>
        </div>
        <div class="buttons">
          <button v-if="ownership" @click.prevent="openEditModal(song)" class="btn btn-left">Edit</button>
          <button v-if="ownership" @click.prevent="openDeleteModal(song)" class="btn-delete">Delete</button>
        </div>
      </div>

      <div v-if="showEditModal" class="modal-overlay" @click.prevent="showEditModal = false">
        <div class="modal-content" @click.stop>
          <h4>Edit this song</h4>
          <input type="text" v-model="selectedSong.title">
          <input type="text" v-model="selectedSong.artist">
          <div class="modal-buttons">
            <button v-if="!savingSong" class="btn-left" @click.prevent="handleEditSong">Save</button>
            <button v-if="savingSong" disabled class="btn-left">Saving</button>
            <button class="btn" @click="showEditModal = false">Cancel</button>
          </div>
          <div v-if="errorRef" class="error text-right">{{ errorRef }}</div>
        </div>
      </div>

      <div v-if="showDeleteModal" class="modal-overlay" @click.prevent="showDeleteModal = false">
        <div class="modal-content" @click.stop>
          <p>Are you sure you want to delete {{ selectedSong.title }}?</p>
          <div class="modal-buttons">
            <button class="btn-left" @click="showDeleteModal = false">Cancel</button>
            <button class="btn-delete" @click.prevent="handleDeleteSong(selectedSong.id)">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AddSong v-if="ownership && showAddSong" :playlist="playlist" :showAddSong="showAddSong" @onCancel="handleCancel" />
</template>

<script setup>
// imports
import getDocument from '@/composables/getDocument'
import getUser from '@/composables/getUser'
import { computed, ref } from 'vue'
import useDocument from '@/composables/useDocument'
import { useRouter } from 'vue-router'
import AddSong from '@/components/AddSong.vue'
import useStorage from '@/composables/useStorage'

// props
const props = defineProps({
  id: {
    type: String,
    required: true
  }
})
const savingSong = ref(false)
const selectedSong = ref(null)
const showDeleteModal = ref(false)
const showEditModal = ref(false)
let showAddSong = ref(false)

const router = useRouter()

const { document: playlist, error } = getDocument('playlists', props.id)

const { errorRef, deleteDocument, updateDocument , isPending } = useDocument('playlists', props.id)

const { deleteImage } = useStorage()

const { user } = getUser()

const ownership = computed(() => {
  return playlist.value && user.value && user.value.uid == playlist.value.userId
})

const handleDeletePlaylist = async () => {
  await deleteImage(playlist.value.filePath)
  await deleteDocument()
  router.push({ name: 'Myplaylists' })
}

const handleDeleteSong = async (id) => {
  const songs = playlist.value.songs.filter(song => song.id != id)
  await updateDocument({ songs })
  showDeleteModal.value = false
  // const songToDelete = playlist.value.songs.filter(song => song.id == id)
  // console.log('Song to Delete: ', songToDelete)
  // console.log('Array filtered: ', songs)
}

const openEditModal = (song) => {
  selectedSong.value = { ...song }
  showEditModal.value = true
}
const openDeleteModal = (song) => {
  selectedSong.value = { ...song }
  showDeleteModal.value = true
}

const handleEditSong = async () => {
  savingSong.value = true
  const updatedSong = {
    id: selectedSong.value.id,
    title: selectedSong.value.title,
    artist: selectedSong.value.artist,
  }
  if (updatedSong.title != '' && updatedSong.artist != '') {
    const songs = playlist.value.songs.filter(song => song.id != updatedSong.id)
    await updateDocument({ songs })
    await updateDocument({
      songs: [...playlist.value.songs, updatedSong]
    })
    savingSong.value = false
    showEditModal.value = false
    // console.log('songs:', songs)
  }
  else {
    errorRef.value = 'Title or Artist cant be empty'
    savingSong.value = false
  }
}

const handleCancel = () => {
  showAddSong.value = false
}
</script>

<style>
.playlist-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 80px;
}

.cover {
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  padding: 160px;
}

.cover img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: 200%;
  max-height: 200%;
}

.playlist-info {
  text-align: center;
}

.playlist-info h2 {
  text-transform: capitalize;
  font-size: 28px;
  margin-top: 20px;
}

.playlist-info p {
  margin-bottom: 20px;
}

.username {
  color: #999;
}

.description {
  text-align: left;
}

.single-song {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed var(--secondary);
  margin-bottom: 20px;
}


.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  justify-content: flex-end;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
}

.btn-left {
  margin-right: 5px;
}

.text-right {
  text-align: right;
}
</style>
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/auth/LoginView.vue'
import SignUpView from '@/views/auth/SignUpView.vue'
import CreatePlaylists from '@/views/playlists/CreatePlaylists.vue'
import PlaylistDetails from '@/views/playlists/PlaylistDetails.vue'
import MyPlaylists from '@/views/playlists/MyPlaylists.vue'


//
import { auth } from '@/firebase/config'

const requireAuth = (to, from, next) => {
  let user = auth.currentUser
  if (!user) {
    next({ name: 'login' })
  } else {
    next()
  }
}

const noAuthRequired = (to, from, next) => {
  let user = auth.currentUser
  if (user) {
    next({ name: 'home' })
  } else {
    next()
  }
}


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: requireAuth
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: noAuthRequired
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUpView,
    beforeEnter: noAuthRequired
  },
  {
    path: '/playlists/user',
    name: 'Myplaylists',
    component: MyPlaylists,
    beforeEnter: requireAuth
  },
  {
    path: '/playlists/create',
    name: 'CreatePlaylists',
    component: CreatePlaylists,
    beforeEnter: requireAuth
  },
  {
    path: '/playlists/:id',
    name: 'PlaylistDetails',
    component: PlaylistDetails,
    beforeEnter: requireAuth,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

import { auth } from '@/firebase/config'
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification, signInWithPopup } from 'firebase/auth'
import { ref } from 'vue'

const error = ref(null)
const isPending = ref(false)
const verified = ref(false)

const signup = async (email, password, displayName) => {
  error.value = null
  isPending.value = true

  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    isPending.value = false
    if (!res) {
      throw new Error('Could not complete the signup')
    }
    await updateProfile(res.user, { displayName })
    await sendEmailVerification(res.user)
    console.log('Email verification sent to: ', res.user)
    error.value = null
    isPending.value = false
    return res
  }
  catch (err) {
    console.log(err.message)
    error.value = err.message
    isPending.value = false
  }
}

const useSignUp = () => {
  return { error, signup, isPending }
}

export default useSignUp
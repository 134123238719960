import { ref, watchEffect } from 'vue'
import { db } from '@/firebase/config'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'

const getCollection = (c, q) => {
  const error = ref(null)
  const documents = ref(null)

  let collectionRef = collection(db, c)
  collectionRef = query(collectionRef, orderBy('createdAt'))
  if (q) {
    collectionRef = query(collectionRef, where(...q))
  }

  const unsub = onSnapshot(collectionRef, snapshot => {
    let results = []
    snapshot.docs.forEach(doc => {
      results.push({ ...doc.data(), id: doc.id })
    })
    documents.value = results
  })

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub())
  })

  return { documents, error }
}

export default getCollection
<template>
  <div class="home">
    <h1>My Playlists</h1>
    <div class="error" v-if="error">
      {{ error }}
    </div>
    <div class="collection" v-if="playlists">
        <ListView :playlists="playlists" />
    </div>
    <RouterLink class="btn" :to="{name: 'CreatePlaylists'}">Create Play</RouterLink>
  </div>
</template>

<script setup>
import getCollection from '@/composables/getCollection'
import ListView from '@/components/ListView.vue'
import getUser from '@/composables/getUser'

const { user } = getUser()

//get Collection
const { documents: playlists, error } = getCollection(
  'playlists',
  ['userId', '==', user.value.uid]
)

</script>
import { db } from '@/firebase/config'
import { ref } from 'vue'
import { collection, addDoc } from 'firebase/firestore'

const useCollection = (c) => {
  const errorRef = ref(null)
  const isPending = ref(false)

  const addDocument = async (doc) => {
    errorRef.value = null
    isPending.value = true
    try {
      const res = await addDoc(collection(db, c), doc )
      isPending.value = false
      return res
    } catch (err) {
      console.log(err.message)
      errorRef.value = 'Could not send the message'
      isPending.value = false
    }
  }

  return { errorRef , addDocument, isPending }
}

export default useCollection
import { initializeApp } from 'firebase/app'
import { getFirestore, serverTimestamp } from 'firebase/firestore'
import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage'
import { getAuth, sendEmailVerification } from 'firebase/auth'


const firebaseConfig = {
  apiKey: 'AIzaSyBO0uLGKkyCwDbPxM24UXE-ocMXSpBZd28',
  authDomain: 'alex-playlist2-138dc.firebaseapp.com',
  projectId: 'alex-playlist2-138dc',
  storageBucket: 'alex-playlist2-138dc.appspot.com',
  messagingSenderId: '170783841330',
  appId: '1:170783841330:web:234b124bd089b1f5c6702b'
}
// Init firebase
initializeApp(firebaseConfig)

// Init services
const db = getFirestore()
const auth = getAuth()
const storage = getStorage()
const storageRef = ref
const timestamp = serverTimestamp()


export { auth, db , storage, timestamp, storageRef, getDownloadURL, uploadBytes, deleteObject, sendEmailVerification }
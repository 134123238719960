<template>
  <div class="modal-overlay" @click="handleOverlayClick">
    <div class="modal-content" @click.stop>
      <h4>Add a new song</h4>
      <input required type="text" placeholder="Song Title" v-model="title">
      <input required type="text" placeholder="Artist" v-model="artist">
      <div class="modal-buttons">
        <button v-if="!isPending" class="btn-left" @click.prevent="handleSubmit">Add Song</button>
        <button v-if="!isPending" class="btn" @click.prevent="handleCancel">Cancel</button>
        <button v-if="isPending" disabled class="btn-left">Adding Song...</button>
      </div>
      <div v-if="errorRef" class="error text-right">{{ errorRef }}</div>
    </div>
  </div>
</template>


<script setup>
// imports
import { ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import useDocument from '@/composables/useDocument'

// props
const props = defineProps({
  playlist: {
    type: Object,
    required: true
  },
  showAddSong: {
    type: Boolean
  }
})

const { errorRef, updateDocument , isPending } = useDocument('playlists', props.playlist.id)

// refs
const title = ref('')
const artist = ref('')

const emit = defineEmits(['onCancel'])

const handleSubmit = async () => {
  if (title.value && artist.value) {
    const newSong = {
      id: uuidv4(),
      title: title.value,
      artist: artist.value,
    }
    console.log('NewSong: ',newSong)
    await updateDocument({
      songs: [...props.playlist.songs, newSong]
    })
    title.value = ''
    artist.value = ''
    emit('onCancel')
  }
  else {
    errorRef.value = 'Please enter a valid title and/or artist'
  }
}

const handleCancel = () => {
  emit('onCancel')
}

const handleOverlayClick = () => {
  emit('onCancel')
}
</script>

<style scoped>
.text-right {
  text-align: right;
}
</style>

<!-- <style scoped>
.add-song {
  text-align: center;
  margin-top: 40px;
}

form {
  max-width: 100%;
  text-align: left;
}
</style> -->
<template>
  <form @submit.prevent="handleSubmit">
    <h4>Create new playlist</h4>
    <input type="text" placeholder="Playlist Title" required v-model="title">
    <input type="textarea" placeholder="Playlist Description..." required v-model="description">
    <label>Upload playlist cover image</label>
    <input type="file" @change="handleChange">
    <div class="error">{{ fileError }}</div>
    <button v-if="!isPending">Create</button>
    <button v-else="isPending" disabled>Saving...</button>
  </form>
</template>

<script setup>
// imports
import { ref } from 'vue'
import useStorage from '@/composables/useStorage'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import { useRouter } from 'vue-router'
import useCollection from '@/composables/useCollection.js'

// router
const router = useRouter()

// refs
const title = ref('')
const description = ref('')
const file = ref(null)
const fileError = ref(null)

// Add to collection
const { addDocument, error } = useCollection('playlists')
const { user } = getUser()

// Local isPending
const isPending = ref(false)

// useStorage
const { url, filePath, uploadImage } = useStorage()

// handleSubmit
const handleSubmit = async () => {
  if (file.value) {
    isPending.value = true
    await uploadImage(file.value)
    const res = await addDocument({
      title: title.value,
      description: description.value,
      userId: user.value.uid,
      username: user.value.displayName,
      coverUrl: url.value,
      filePath: filePath.value,
      songs: [],
      createdAt: timestamp
    })
    isPending.value = false
    if (!error) {
      router.push({ name: 'PlaylistDetails', params: { id: res.id }})
      // console.log('Playlist added. ID: ' , res.id)
      title.value = ''
      description.value =''
      file.value = null
    }
    // console.log('image uploaded, url: ', url.value)
  } else {
    fileError.value = 'Cant create this playlist'
  }
}

// allowed file types
const types = ['image/png', 'image/jpeg']

//handleChange
const handleChange = (e) => {
  const selected = e.target.files[0]
  if (selected && types.includes(selected.type)) {
    file.value = selected
    fileError.value = null
    console.log('file', file.value)
  } else {
    fileError.value = 'Please select a valid image file (jpg or png)'
    file.value = null
  }

}

</script>

<style>
input[type="file"] {
  border: 0;
  padding: 0;
}

label {
  font-size: 12px;
  display: block;
  margin-top: 30px;
}

button {
  margin-top: 20px;
}
</style>
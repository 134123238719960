import { storage, storageRef, getDownloadURL, uploadBytes, deleteObject } from '@/firebase/config'
import { ref } from 'vue'
import getUser from './getUser'
// import { getDownloadURL, uploadBytes } from 'firebase/storage'

// user
const { user } = getUser()

const useStorage = () => {
  const error = ref(null)
  const url = ref(null)
  const filePath = ref(null)

  const uploadImage = async (file) => {
    filePath.value = `covers/${user.value.uid}/${file.name}`
    const storageReference = storageRef(storage, filePath.value)

    try {
      console.log('Starting file upload...')
      await uploadBytes(storageReference, file)
      console.log('File uploaded, fetching download URL...')
      url.value = await getDownloadURL(storageReference)
      // console.log('Download URL fetched:', url.value)
    } catch (err) {
      console.log('Error during upload:', err.message)
      error.value = err.message
    }
  }

  const deleteImage = async (path) => {
    const error = ref(null)
    const fileRef = storageRef(storage, path)

    try {
      await deleteObject(fileRef)
      console.log('Cover deleted: ', path)
    } catch (err) {
      error.value = err.message
      console.log(error.value)
    }
  }

  return { url, filePath, error, uploadImage, deleteImage }
}

export default useStorage